<script setup>
import { onUpdated } from 'vue';

const scrollY = useScroll();

const emits = defineEmits(['openPopUpConnection']);

const mouseEnter = ref(false);
const mouseLeave = ref(false);

const navItem = [
    {
        name: 'Главная',
        link: '/',
        subLink: []
    },
    {
        name: 'Кондиционирование',
        link: '/conditioning',
        subLink: [
            {
                name: 'Установка кондиционеров',
                link: '/conditioning/installing-air-conditioner',
                subLink: []
            },
            {
                name: 'Ремонт кондиционеров',
                link: '/conditioning/repair-air-conditioner',
                subLink: []
            },
            {
                name: 'Обслуживание кондиционеров',
                link: '/conditioning/air-conditioning-maintenance',
                subLink: []
            },
        ]
    },
    {
        name: 'Вентиляция',
        link: '/ventilation',
        subLink: [
            {
                name: 'Установка вентиляции',
                link: '/ventilation/installation-ventilation',
                subLink: []
            },
            {
                name: 'Ремонт вентиляции',
                link: '/ventilation/repair-ventilation',
                subLink: []
            },
            {
                name: 'Обслуживание вентиляции',
                link: '/ventilation/ventilation-maintenance',
                subLink: []
            },
        ]
    },
    {
        name: 'Каталог кондиционеров',
        link: '/catalog',
        subLink: [
            {
                name: 'Кондиционеры настенные',
                link: '/catalog/split-system',
                subLink: []
            },
            {
                name: 'Кондиционеры мульти-сплит',
                link: '/catalog/multi-split-system',
                subLink: []
            },
            {
                name: 'Кондиционеры кассетные',
                link: '/catalog/kassetnie',
                subLink: []
            },
            {
                name: 'Кондиционеры напольно-потолочные',
                link: '/catalog/napolno-potolochnie',
                subLink: []
            },
            {
                name: 'Кондиционеры колонные',
                link: '/catalog/kolonnie',
                subLink: []
            },
            {
                name: 'Кондиционеры канальные',
                link: '/catalog/kanalnie',
                subLink: []
            }
        ]
    },
    {
        name: 'Статьи',
        link: '/articles',
        subLink: []
    },
    {
        name: 'О Нас',
        link: '/about',
        subLink: []
    },
    {
        name: 'Контакты',
        link: '/contacts',
        subLink: []
    }
];

const handlerMenuItem = (e) => {
    if (e.type === 'mouseenter') {
        mouseEnter.value = true;
        mouseLeave.value = false;
    }
    else {
        mouseLeave.value = true;
        mouseEnter.value = false;
    }

    e.target.classList.toggle('header-tablet__nav-list-item-text_active-subitem');
}

const handlerOpenPopUp = () => {
    emits('openPopUpConnection');
};

const handlerTo = (link) => {
    navigateTo(link);
};

onUpdated(() => {
    if (!mouseEnter.value) return;

    const elMenuActive = document.querySelector('.header-tablet__nav-list-item-text_active');
    elMenuActive.classList.add('header-tablet__nav-list-item-text_active-subitem');
});
</script>

<template>
    <header id="header" class="header-tablet">
        <div class="header-tablet__content-wrapper">
            <div class="header-tablet__content">
                <img class="header-tablet__logo" src="@/assets/img/logo.svg" alt="Air Arts" title="Логотип Air Arts" @click="handlerTo('/')" />
                <div class="header-tablet__logo-text" @click="handlerTo('/')">Air Arts</div>
                <div class="header-tablet__logo-description" @click="handlerTo('/')">for fresh air</div>
                <nav class="header-tablet__nav">
                    <ul class="header-tablet__nav-list">
                        <li v-for="item in navItem" :key="item.name" class="header-tablet__nav-list-item">
                            <div class="header-tablet__nav-list-item-text" @mouseenter="handlerMenuItem"
                                @mouseleave="handlerMenuItem"
                                :class="{ 'header-tablet__nav-list-item-text_active': $route.fullPath === item.link || ($route.fullPath.includes(item.link) && item.link !== '/'), 'header-tablet__nav-list-item-text_subitem': item.subLink.length }">
                                <NuxtLink :to="item.link" :key="item.link" class="header-tablet__nav-list-item-text">
                                    {{ item.name }}
                                </NuxtLink>
                                <NuxtImg v-if="item.subLink.length" src="/shevron.svg"
                                    alt="Изображение указателя статуса меню" title="Иконка статуса меню" width="10" height="10"
                                    class="header-tablet__nav-list-item-shevron" />
                                <div v-if="item.subLink.length" class="header-tablet__nav-list-item-sublink-wrapper">
                                    <ul class="header-tablet__nav-list-sublink">
                                        <li v-for="sublink in item.subLink" :key="sublink.link"
                                            class="header-tablet__nav-list-item">
                                            <NuxtLink :to="sublink.link" :key="sublink.link"
                                                class="header-tablet__nav-list-item-sublink">{{ sublink.name }}
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
                <ButtonComponent class="header-tablet__button" text="Связаться" @click="handlerOpenPopUp" />
                <ClientOnly>
                    <a href="https://yandex.ru/profile/97416138360?intent=reviews" target="_blank"
                        class="header-tablet__link-rating">
                        <div class="header-tablet__link-rating-wrapper">
                            <a class="RatingBadgeWidget" target="_blank"
                                href="//yandex.ru/maps/org/97416138360/reviews?utm_source=badge&amp;utm_medium=rating&amp;utm_campaign=v1"
                                style="background-image:url(https://avatars.mds.yandex.net/get-altay/474904/badge_rating_light_4.8/orig)">
                            </a>
                        </div>
                    </a>
                </ClientOnly>
            </div>
        </div>
    </header>
    <Transition>
        <div v-if="scrollY" class="header-tablet" :class="{ 'header-tablet_active': scrollY }">
            <div class="header-tablet__content-wrapper">
                <div class="header-tablet__content">
                    <img class="header-tablet__logo" src="@/assets/img/logo.svg" alt="Air Arts" title="Логотип Air Arts"
                        @click="handlerTo('/')" />
                    <div class="header-tablet__logo-text" @click="handlerTo('/')">Air Arts</div>
                    <div class="header-tablet__logo-description" @click="handlerTo('/')">for fresh air</div>
                    <ButtonComponent class="header-tablet__button" text="Связаться" @click="handlerOpenPopUp" />
                </div>
            </div>
        </div>
    </Transition>
</template>

<style lang="less">
.header-tablet {
    width: 100%;
    position: relative;
    padding-top: 19px;
    padding-bottom: 20px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    z-index: 1;
    background-color: rgba(217, 250, 221, 0);

    .RatingBadgeWidget {
        display: block;
        width: 150px;
        height: 31.5px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;
    }

    &__content {
        width: 1440px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__link-rating {
        position: absolute;
        right: 20px;
        bottom: -40px;
        width: 150px;
        height: 32px;
        text-decoration: none;
        touch-action: manipulation;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

        &-wrapper {
            position: absolute;
            width: 150px;
            height: 31.5px;
            border-radius: 5px;
            overflow: hidden;
        }
    }

    &_active {
        position: fixed;
        top: 0;
        z-index: 100;
        backdrop-filter: blur(8px);
        background: rgba(217, 250, 221, 0.55);

        .header-tablet__button.button {
            border-radius: 20px;
            background-color: rgba(120, 192, 129, 0.25);
        }
    }

    &__logo {
        margin-left: 20px;
        width: 116px;
        height: 75px;
        margin-right: 64px;
        cursor: pointer;

        &-text {
            font-family: 'Ruberoid';
            font-weight: 900;
            font-size: 2rem;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--text);
            position: absolute;
            left: 109px;
            top: 23px;
            cursor: pointer;
        }

        &-description {
            font-family: 'Ruberoid';
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--text2);
            position: absolute;
            left: 109px;
            top: 50px;
            cursor: pointer;
        }
    }

    &__nav {
        display: inline;
        margin: 0 20px;

        &-list {
            height: 75px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0;
            max-width: 54vw;
            margin-left: 0;

            &-sublink {
                display: none;
                width: 100%;
                position: absolute;
                top: 33px;
                left: 0;
                -webkit-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
                background: rgba(217, 250, 221, 1);
                z-index: 500000000;
                padding: 7px 20px 5px 20px;

                &-wrapper {
                    position: absolute;
                }
            }

            &-item {
                list-style: none;

                &-sublink {
                    color: var(--text);
                    transition: color 0.3s;
                    text-decoration: none;
                    display: block;
                    padding: 5px 20px 10px;

                    &:hover {
                        color: var(--text2);
                        transition: color 0.3s;
                    }
                }

                &-shevron {
                    margin-left: 2px;
                    padding-top: 5px;
                    transform: rotateX(0);
                    transition: transform 0.3s;
                }

                &-text {
                    padding: 10px 20px 5px;
                    width: 135%;
                    font-family: 'Proxima Nova Rg';
                    font-size: 1.5rem;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    text-transform: lowercase;
                    cursor: pointer;
                    position: relative;
                    background-color: transparent;
                    color: var(--text);
                    transition: color 0.3s;
                    text-decoration: none;

                    &_active-subitem {
                        .header-tablet__nav-list-sublink {
                            display: block;
                        }
                    }


                    &:hover {
                        color: var(--text2);
                        transition: color 0.3s;
                    }

                    &_active {
                        .header-tablet__nav-list-item-text {
                            color: var(--text2);
                        }
                    }

                    &_subitem {
                        &:hover {
                            -webkit-backdrop-filter: blur(8px);
                            backdrop-filter: blur(8px);
                            background-color: rgba(217, 250, 221, 1);
                            transition: color 0.3s;
                            z-index: 500000000;

                            .header-tablet__nav-list-item-shevron {
                                transform: rotateX(180deg) translateY(-3px);
                                transition: transform 0.3s;
                            }
                        }
                    }
                }
            }
        }
    }

    &__button {
        width: 210px;
        min-width: 210px;
        height: 75px;
        margin-right: 20px;
        border-radius: 17px;
        box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
    }

}

.v-enter-active,
.v-leave-active {
    opacity: 1;
    transition: opacity .3s ease .1s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
    transition: opacity .3s ease;
}
</style>
